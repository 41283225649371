<template>
	<v-container fluid class="pa-5">
		<v-row>
			<Actions/>

			<v-col cols="12 pb-1">
				<v-tabs 
					class="rounded-t-lg" 
					background-color="#192335" 
					color="#fff" 
					align-with-title 
					grow
				>
					<v-tabs-slider color="primary"></v-tabs-slider>

					<v-tab v-for="d in data" :key="d.name" :to="'/streamerpanel/' + d.route">
						{{ d.name }}
					</v-tab>
				</v-tabs>
			</v-col>

			<v-col class="py-0 rounded-b-lg">
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Actions from './Actions/Actions.vue';

export default {
	components: {
		Actions,
	},

	data() {
		return {
			data: [
				{
					name: 'Rewards',
					route: 'rewards',
					role_required: 1,
				},
				{
					name: 'Users',
					route: 'users',
					role_required: 1,
				},
				{
					name: 'Team',
					route: 'team',
					role_required: 1,
				},
				{
					name: 'Categories',
					route: 'categories',
					role_required: 1,
				},
				{
					name: 'Earn Rates',
					route: 'earnrates',
					role_required: 1,
				},
				{
					name: 'Commands',
					route: 'commands',
					role_required: 2,
				},
				{
					name: 'Interval Messages',
					route: 'intervalmessages',
					role_required: 2,
				},
				{
					name: 'Stream Info',
					route: 'streaminfo',
					role_required: 2,
				},
			]
		}
	},

	created() {
		this.data = this.data.filter(route => this.$store.state.role_id <= route.role_required);
	},
}
</script>