<template>
	<div>
		<v-tabs background-color="#192335" color="#fff" v-model="tab" align-with-title>
			<v-tabs-slider color="primary"></v-tabs-slider>

			<v-tab v-for="logs in data" :key="logs.name">
				{{ logs.name }}
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item v-for="logs in data" :key="logs.name">
				<v-simple-table class="pa-2">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Message</th>
								<th class="text-left">Timestamp</th>
							</tr>
						</thead>
						
						<tbody>
							<tr v-for="item in logs.logs" :key="item.service">
								<td>
									<span>{{ item.msg }}</span>
								</td>
								<td>
									<span>{{ dateFormat(item.meta.logger.time) }}</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
export default {
	props: ['data'],

	data() {
		return {
			tab: 0
		}
	},

	methods: {
		dateFormat(input) {
			return new Date(input).toLocaleString('en-GB', { timeZone: 'Europe/Athens' })
		}
	},
}
</script>