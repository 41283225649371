var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.role_id === 1)?_c('v-col',{staticClass:"d-flex flex-column align-self-stretch px-4 pb-1",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"5","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"error","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Reset all points ")],1)]}}],null,false,985656080),model:{value:(_vm.dialogReset),callback:function ($$v) {_vm.dialogReset=$$v},expression:"dialogReset"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Reset all points ")],1),_c('v-card-text',[_vm._v(" Are you sure you want to reset all users points? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialogReset = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loadingResetBtn,"disabled":_vm.loadingResetBtn},on:{"click":function($event){return _vm.ResetAllPoints()}}},[_vm._v("Reset")]),_c('v-spacer')],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"5","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"450px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"warning","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Reset channel points ")],1)]}}],null,false,2526488800),model:{value:(_vm.dialogReset2),callback:function ($$v) {_vm.dialogReset2=$$v},expression:"dialogReset2"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Reset your channel points ")],1),_c('v-card-text',[_vm._v(" Are you sure you want to reset all your channel points? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialogReset2 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loadingResetBtn2,"disabled":_vm.loadingResetBtn2},on:{"click":function($event){return _vm.ResetChannelPoints()}}},[_vm._v("Reset")]),_c('v-spacer')],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"5","sm":"6"}},[_c('v-dialog',{attrs:{"width":"1400px","height":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"blue lighten-2","outlined":""},on:{"click":_vm.intervalSystemInfo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-application-cog-outline")]),_vm._v(" Manage System ")],1)]}}],null,false,3380107229),model:{value:(_vm.dialogSystem),callback:function ($$v) {_vm.dialogSystem=$$v},expression:"dialogSystem"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-application-cog-outline")]),_c('h3',[_vm._v("Manage System - PM2 Daemon")]),(_vm.processesLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":_vm.clearProcsInterval}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3776062951)},[_c('span',[_vm._v("Close")])])],1),_c('v-card-text',[_c('v-simple-table',{staticClass:"pa-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("PM2 ID")]),_c('th',{staticClass:"text-left"},[_vm._v("PID")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Version")]),_c('th',{staticClass:"text-left"},[_vm._v("NodeJS version")]),_c('th',{staticClass:"text-left"},[_vm._v("CPU Usage")]),_c('th',{staticClass:"text-left"},[_vm._v("Memory")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.procs),function(proc){return _c('Process',{key:proc.pm_id,attrs:{"proc":proc}})}),1)]},proxy:true}],null,false,4264542979)})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"5","sm":"6"}},[_c('v-dialog',{attrs:{"width":"1800px","height":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"green lighten-2","outlined":""},on:{"click":_vm.getSystemLogs}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-list-box-outline")]),_vm._v(" Logs ")],1)]}}],null,false,102981597),model:{value:(_vm.dialogLogs),callback:function ($$v) {_vm.dialogLogs=$$v},expression:"dialogLogs"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-list-box-outline")]),_c('h3',[_vm._v("Logs")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogLogs = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1988240078)},[_c('span',[_vm._v("Close")])])],1),_c('v-card-text',[_c('Logs',{attrs:{"data":_vm.logs}})],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }